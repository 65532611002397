import { NgModule, importProvidersFrom } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import {
  AlertService,
  CreateApiService,
  DeleteApiService,
  ReadApiService,
  UpdateApiService,
  TruncateTextPipe,
  NumberCommaDirective,
  ModulePreloadingStrategyService,
} from "@/shared/index";
import {
  BreadCrumbsComponent,
  CustomPhoneInputComponent,
  LoaderComponent,
  MortgageCalculatorComponent,
  FindOutMoreModalComponent,
  RequestServiceModalComponent,
  RequestBoxComponent,
  ScheduleTourModalComponent,
  PropertyCardComponent,
  AdsCardComponent,
  PopupComponent,
  FloatingButtonComponent,
  AddWishListComponent,
  SesoCarouselComponent,
  LuxuryTourCardComponent
} from "./";
import { LightboxModule } from 'ngx-lightbox';


@NgModule({ declarations: [
        BreadCrumbsComponent,
        CustomPhoneInputComponent,
        LoaderComponent,
        MortgageCalculatorComponent,
        FindOutMoreModalComponent,
        RequestBoxComponent,
        ScheduleTourModalComponent,
        RequestServiceModalComponent,
        PropertyCardComponent,
        TruncateTextPipe,
        AdsCardComponent,
        PopupComponent,
        FloatingButtonComponent,
        AddWishListComponent,
        NumberCommaDirective,
        SesoCarouselComponent,
        LuxuryTourCardComponent
    ],
    exports: [
        BreadCrumbsComponent,
        CustomPhoneInputComponent,
        LoaderComponent,
        MortgageCalculatorComponent,
        FindOutMoreModalComponent,
        RequestBoxComponent,
        ScheduleTourModalComponent,
        RequestServiceModalComponent,
        PropertyCardComponent,
        AdsCardComponent,
        TruncateTextPipe,
        PopupComponent,
        FloatingButtonComponent,
        AddWishListComponent,
        SesoCarouselComponent,
        LuxuryTourCardComponent
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgMultiSelectDropDownModule,
        LightboxModule], providers: [
        AlertService,
        CreateApiService,
        DeleteApiService,
        ReadApiService,
        UpdateApiService,
        ModulePreloadingStrategyService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class UtilModule { }
