import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { SearchObject, SearchStoreService, AESEncryptDecryptService } from '@/shared';
declare var jquery: any;
import Swal from "sweetalert2";
declare var $: any;
import { filter } from 'rxjs';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public serviceLink: boolean = false;
  expanded: boolean = false;
  public userDetails: any;
  showProperty: boolean = false;
  showLocation: boolean = false;
  showPrice: boolean = false;
  showPropertyH: boolean = false;
  showLocationH: boolean = false;
  showPriceH: boolean = false;
  showSearch: boolean = false;
  toggleDropdown: boolean = false;
  toggleDropdownM: boolean = false;
  toggleDropdownMT: boolean = false;
  public fetchData: boolean = false;
  public loggedInBuyer: any;
  public profileText: string = "Profile";
  public searchObj$!: Observable<SearchObject>;
  public searchObj: SearchObject = {};
  public crmHost: string;
  public rawDetails?: string;

  constructor(private _router: Router, private _searchStoreService: SearchStoreService, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.serviceLink = true;
    this.toggleDropdown = false;
    $('li.dropdown').on({
      'shown.bs.dropdown': function () {
        this.close = false;
      },
      click: function () {
        this.close = true;
      },
      'hide.bs.dropdown': function () {
        return this.close;
      },
    });

    const buyerLoggedIn = sessionStorage.getItem('loggedIn') || '{}';
    this.loggedInBuyer = JSON.parse(buyerLoggedIn);
    let url = window.location.href;
    let urlParams = url.split('/');

    this.crmHost = `${urlParams[0]}//${urlParams[2]}/crm`;
  }

  ngOnInit() {
        const rawDetails = sessionStorage.getItem('currentUser') || '{}';
        this.rawDetails = rawDetails;
        this.userDetails = JSON.parse(rawDetails);
        if (this.userDetails && Object.keys(this.userDetails).length !== 0) {
            this.verifyUser(this.userDetails.user);
        }else{
          this.userDetails = null;
        }

        const searchObj = sessionStorage.getItem('searchObj') || '{}';
        this.searchObj = JSON.parse(searchObj);

        this._searchStoreService.update(this.searchObj);

        this.searchObj$ = this._searchStoreService.searchDetails;
        this.searchObj$.subscribe((data: SearchObject) => {
          if(Object.keys(data).length !== 0)
          {
            this.displaySearch(data.searchSection!);
          }
        });
    }

  toggleMenu() {
    this.expanded = !this.expanded;
  }

  gotToAboutUse() {
    location.replace('https://seso.global/aboutus/');
  }

  goToAffiliatePartnership(){
    location.replace('https://affiliates.seso.global/home');
  }


  closeSearchDisplay(){
    this.showSearch = false;
    this.showProperty = false;
    this.showLocation = false;
    this.showPrice = false;
    this.showPropertyH = false;
    this.showLocationH = false;
    this.showPriceH = false;
    this.fetchData = true;
    //this._searchStoreService.switchSearch('none');
  }

  displayAllSearch() {
    this.showSearch = true;
    this.showProperty = true;
    this.showLocation = true;
    this.showPrice = true;
    this.showPropertyH = false;
    this.showLocationH = false;
    this.showPriceH = false;
    this.fetchData = true;
  }

  displaySearch(section: string) {
    this.showSearch = true;
    this.showProperty = false;
    this.showLocation = false;
    this.showPrice = false;
    this.showPropertyH = false;
    this.showLocationH = false;
    this.showPriceH = false;
    this.fetchData = true;

    switch (section) {
      case 'all':
        this.displayAllSearch();
        break;
      case 'property':
        this.showProperty = true;
        this.showPropertyH = true;
        break;
      case 'location':
        this.showLocation = true;
        this.showLocationH = true;
        break;
      case 'price':
        this.showPrice = true;
        this.showPriceH = true;
        break;
      case 'none':
        this.closeSearchDisplay();
        break;
    }
  }


  switchSearchDisplay(section: string) {
    this._searchStoreService.switchSearch(section);
  }

  toggleDB() {
    this.toggleDropdown = !this.toggleDropdown;
  }

  toggleMDB() {
    this.toggleDropdownM = !this.toggleDropdownM;
  }

  toggleMDBT() {
    this.toggleDropdownMT = !this.toggleDropdownMT;
  }

  public profileDropDown: boolean = false;
  toggleProfileMenu(){
    this.profileDropDown = !this.profileDropDown;
  }

  goToUserProfile(): any {
        let data = {...this.userDetails};
        if (Object.keys(data.details).length !== 0) {
            const myRole = data.user.roleName;
            if (myRole === 'buyer' ||  myRole === 'tenant') {
                return this._router.navigate(['/user-profile']);
            }

            let encryptedText = encodeURIComponent(this._AESEncryptDecryptService.encrypt(this.rawDetails));
            // let decryptedText = this._AESEncryptDecryptService.decrypt(decodeURIComponent(encryptedText));

            const companyType = data.details[0]['company']['companyType']['companyTypeName'];

            /*let keysToRemove = ['currentUser', 'loggedIn'];

            for (let key of keysToRemove) {
                sessionStorage.removeItem(key);
            }
            sessionStorage.clear();*/

            switch (companyType) {
                case 'developer':
                    if (!data.isAccountVerified) {
                        return this._router.navigate(['/validate-account']);
                    } else {
                        window.location.href = `${this.crmHost}/auth/${encryptedText}`;
                    }
                    break;
                case 'buyer':
                    return this._router.navigate(['/user-profile']);

                case 'Agent':
                    // code...
                    // this.loading = false;
                    if (!data.isAccountVerified) {
                        return this._router.navigate(['/validate-account']);
                    } else if (!data.isAccountSetup) {
                        return this._router.navigate(['/account-setup']);
                    } else {
                        window.location.href = `${this.crmHost}/auth/${encryptedText}`;
                    }
                    break;
                case 'service-provider':
                    // code...
                    // this.loading = false;
                    if (!data.isAccountVerified) {
                        return this._router.navigate(['/validate-account']);
                    } else if (!data.isAccountSetup) {
                        return this._router.navigate(['/account-setup']);
                    } else {
                        window.location.href = `${this.crmHost}/auth/${encryptedText}`;
                    }
                    break;
                case 'Bank':
                    // this.loading = false;
                    if (!data.isAccountSetup) {
                        return this._router.navigate(['/account-setup']);
                    } else {
                        window.location.href = `${this.crmHost}/auth/${encryptedText}`;
                    }
                    break;
                case 'super-admin':
                    // this.loading = false;
                    if (!data.isAccountSetup) {
                        return this._router.navigate(['/account-setup']);
                    } else {
                        window.location.href = `${this.crmHost}/auth/${encryptedText}`;
                    }
                    break;
                default:
                    this.logoutUser();
                    break;
            }

        }
    }


  isResponsive = false;

  toggleResponsive() {
    this.isResponsive = !this.isResponsive;
  }

  searchbarDropdown(url: string){
      if(url.includes('/search-results/search')){
        this.showSearch = true;
        this.displayAllSearch();
      }else{
        this.showSearch = false;
      }
    }
  isMarketplaceProfile = false;
  isCrmProfile = false;
  verifyUser(user: any) {
    this.isMarketplaceProfile = false;
    this.isCrmProfile = false;
      switch (user.roleName) {
          case 'buyer':
          case 'tenant':
              this.profileText = 'Profile';
              this.isMarketplaceProfile = true;
              break;
          default:
              this.profileText = "CRM Profile"
              this.isCrmProfile = true;
              break;
      }


    }

  showAlert() {
        const self = this;
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will be logged out of your account!',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result:any) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            //Swal.fire('Saved!', '', 'success')
            self.logoutUser();
          }
        })
    }

  logoutUser() {

        let keysToRemove = ['currentUser', 'loggedIn'];

        for (let key of keysToRemove) {
            sessionStorage.removeItem(key);
        }
        sessionStorage.clear();
        this._router.navigate(['/']);

        setTimeout(function () {
            window.location.reload();
        });
    }
}
