import { AnalyticsEvent, AnalyticsEventType, LocationInfo } from '@/shared/_interfaces/analytics';
import { AnalyticsService } from '@/shared/_services/analytics.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  title = 'seso-market-place';

  constructor(private router: Router, private analyticsService: AnalyticsService) { }

  ngOnInit(): void {

    const routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async (event) => {
        const location = await this.analyticsService.setGeolocationDetails()
        const session = this.analyticsService.setUUIDCookie()
        this.recordEvent(event, location, session)

      })
    this.subscriptions.push(routerSubscription)


  }

  recordEvent(event: any, gelocation: any, session: string) {
    const pathSegments = event.url.split('/');
    const location: LocationInfo | undefined = JSON.parse(gelocation)

    let eventType = AnalyticsEventType.PageView
    let modifiedUrl = event.url

    if (event.url.includes('adId=')) {
      eventType = AnalyticsEventType.AdView
      modifiedUrl = `/ad/${this.getQueryParam(event.url, 'adId')}`
    } else if (event.url.includes('property-details')) {
      eventType = AnalyticsEventType.PropertyView
      modifiedUrl = `/${pathSegments[1]}/${pathSegments[2]}`
    }

    const data: AnalyticsEvent = {
      current_page: modifiedUrl,
      previous_page: event.urlAfterRedirects,
      city: location?.city,
      country: location?.country,
      type: eventType,
      meta_data: navigator.userAgent,
      origin: window.location.hostname,
      analytics_code: environment._analyticsCode,
      sid: session,
      page_title: document.title
    }
    const analyticsSubs = this.analyticsService.sendAnalytics(data).subscribe(data => {

    }, error => {
      if (!environment.production) {
        console.log("failed to record analytics")
      }
    });
    this.subscriptions.push(analyticsSubs)
  }

  getQueryParam(url: string, key: string) {
    if (!url.includes('?')) {
      return null;
    }
    const queryString = url.split('?')[1];

    const queryParams = new URLSearchParams(queryString);
    return queryParams.get(key);
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
