import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NumberCommaDirective, ReadApiService, SearchModel, SearchObject, SearchStoreService } from '@/shared';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { filter } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.css'],
  providers: [NumberCommaDirective]
})
export class SearchComponentComponent implements OnChanges {
  @Input("showProperty") showProperty: any;
  @Input("showLocation") showLocation: any;
  @Input("showPrice") showPrice: any;
  @Input("showSearch") showSearch: boolean = false;
  @Input("fetchData") fetchData: any;
  public model: any = new SearchModel();
  public value: any;
  public allProperties: any = [];
  public loadingProperties: boolean = false;
  public loadingMoreProperties: boolean = false;
  public countries: any = [];
  public cities: any = [];
  public tempCities: any;
  public propertyType: any = [];
  public account: any;
  public searchTermModel: any = {};
  public queryString: string | undefined;
  public dropdownList = [];
  public selectedCityItems: any = [];
  public selectedCountryItems: any = [];
  public selectedSuburbItems: any = [];
  public cityDropdownSettings: IDropdownSettings = {};
  public countryDropdownSettings: IDropdownSettings = {};
  public suburbDropdownSettings: IDropdownSettings = {};
  public suburbs: any = [];
  // @ts-ignore
  public disableSuburbs: boolean;
  public itemIds: any = [];
  public bedRoomControlDisabled: boolean = false;
  public bathRoomControlDisabled: boolean = false;
  public warn: boolean = false;
  // @ts-ignore
  public propertyTypeClass: string;
  // @ts-ignore
  public formClas: string;
  // @ts-ignore
  public bedRoomsClass: string;
  // @ts-ignore
  public bathRoomsClass: string;
  // @ts-ignore
  public countryClass: string;
  // @ts-ignore
  public cityClass: string;
  // @ts-ignore
  public suburbClass: string;
  // @ts-ignore
  public maxPriceClass: string;
  // @ts-ignore
  public minPriceClass: string;

  public bedName = 'Bedroom'
  public bathName = 'Bathroom'
  public propertyTypeName = 'Property Type'
  // @ts-ignore
  public loadingCountries: boolean;
  // @ts-ignore
  public loadingCities: boolean;
  public searchObj$!: Observable<SearchObject>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _readApiService: ReadApiService,
    private _searchStoreService: SearchStoreService) {
    this.account = sessionStorage.getItem('account');
    this.queryString = `account=${this.account}`;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.searchbarDropdown(event.url);
    });
  }

  ngOnInit(): void {
    this.searchTermModel.max_price = '0';
    this.searchTermModel.min_price = '0';
    // this.innitDropDowns();
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.searchbarDropdown(event.url);
    });

    this.searchObj$ = this._searchStoreService.searchDetails;
    this.searchObj$.subscribe((data: SearchObject) => {

      this.searchTermModel = {
        property_location: data.property_location,
        countryId: data.countryId,
        cityId: data.property_location,
        suburbId: data.suburbId,
        propType: data.type,
        bedroom: data.bedroom,
        bathroom: data.bathroom,
        min_price: data.minPrice,
        max_price: data.maxPrice,
      };
      this.selectedCountryItems = data.selectedCountryItems!;
      this.selectedCityItems = data.selectedCityItems!;
      this.selectedSuburbItems = data.selectedSuburbItems!;
    });
  }

  compare(a: any, b: any): any {
    // Use toUpperCase() to ignore character casing
    const bandA = a.cityName.toUpperCase();
    const bandB = b.cityName.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  getCities() {
    this._readApiService.readAllService('user/superadmin/city')
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // //log,debug('Request sent!');
            this.loadingCities = true;
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.Response:
            let result = event.body;
            this.loadingCities = false;
            if (result.status === 200) {
              this.cities = result.data ? result.data.sort(this.compare) : [];
              this.tempCities = result.data ? result.data.sort(this.compare) : [];
              for (let city of this.cities) {
                // @ts-ignore
                this.dropdownList.push({
                  item_id: city.cityId,
                  item_text: city.cityName,
                  ...city
                });
              }
              this.populateSearchFields();
            }
            break;
        }
      });
  }

  getActiveCountries() {
    this._readApiService.readAllService('user/superadmin/country_active')
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.loadingCountries = true;
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.Response:
            let result = event.body;
            this.loadingCountries = false;

            if (result.status === 200) {
              this.countries = result.data ? result.data : [];
              this.populateSearchFields()

            }
            break;
        }
      });
  }

  getPropertyType() {
    this._readApiService.readAllService('user/superadmin/property_type/')
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // //log,debug('Request sent!');
            break;
          case HttpEventType.Response:
            // //log,debug('😺 Done!', event.body);

            let result = event.body;

            if (result.status === 200) {
              this.propertyType = result.data;
            }
            break;
        }
      });
  }

  submitSearch() {
    this.searchProperties(this.searchTermModel);
  }

  searchProperties(searchData: any) {
    let data: any = {};
    let regex = /[.,\s]/g;
    if (this.selectedCityItems?.length) {
      // @ts-ignore
      searchData.property_location = this.selectedCityItems.map(item => item.cityId);
    }
    if (this.selectedCountryItems?.length) {
      // @ts-ignore
      searchData.countryId = this.selectedCountryItems.map(item => item.id);
    }

    if (this.selectedSuburbItems?.length) {
      // @ts-ignore
      searchData.suburbId = this.selectedSuburbItems.map(item => item.id);
    }

    searchData.min_price = (searchData.min_price) ? searchData.min_price.toString() : '';
    searchData.max_price = (searchData.max_price) ? searchData.max_price.toString() : '';

    const searchObj: SearchObject = {
      searchSection: 'all',
      location: searchData.property_location,
      countryId: searchData.countryId,
      property_location: searchData.property_location,
      type: searchData.propType,
      bedroom: searchData.bedroom,
      bathroom: searchData.bathroom,
      minPrice: searchData.min_price,
      maxPrice: searchData.max_price,
      selectedCountryItems: this.selectedCountryItems,
      selectedCityItems: this.selectedCityItems,
      selectedSuburbItems: this.selectedSuburbItems,
    };
    this._searchStoreService.update(searchObj);

    if (searchData.countryId) {
      delete data['q'];
      data['countryId'] = searchData.countryId;
    }
    if (searchData.property_location) {
      delete data['q'];
      data['location'] = searchData.property_location;
    }
    if (searchData.suburbId) {
      delete data['q'];
      data['suburbId'] = searchData.suburbId;
    }
    if (searchData.propType) {
      delete data['q'];
      data['type'] = searchData.propType;
    }
    if (searchData.min_price) {
      delete data['q'];
      data['minPrice'] = searchData.min_price.replace(regex, '');
    }
    if (searchData.max_price) {
      delete data['q'];
      data['maxPrice'] = searchData.max_price.replace(regex, '');
    }
    if (searchData.bedroom) {
      delete data['q'];
      data['bedroom'] = searchData.bedroom.replace(regex, '');
    }
    if (searchData.bathroom) {
      delete data['q'];
      data['bathroom'] = searchData.bathroom.replace(regex, '');
    }

    if (searchData.q) {
      delete data['location'];
      delete data['countryId'];
      delete data['cityId'];
      delete data['type'];
      delete data['bedroom'];
      delete data['bathroom'];
      delete data['minPrice'];
      delete data['maxPrice'];

      data['q'] = searchData.q;
    }
    this._router.navigate(['/search-results/search'], { queryParams: data, state: { activeOption: 1 } });
  }

  onChange(element: any) {
    this.cities = [];
    if (element.value != "0: undefined") {
      for (const city of this.tempCities) {
        if (city.countryId == element.value) {
          this.cities.push(city);
        }
      }
    } else {
      this.cities = this.tempCities;
      return;
    }
  }

  //suburb dropdown functions
  public onSuburbItemSelect(item: any) {
    sessionStorage.setItem('selectedSuburbItems', JSON.stringify(this.selectedSuburbItems));
  }

  public onSuburbSelectAll(items: any) {
    sessionStorage.setItem('selectedSuburbItems', JSON.stringify(this.selectedSuburbItems));
  }

  public onSuburbItemDeSelect(item: any) {
    // @ts-ignore
    let selectedSuburbItems = this.selectedSuburbItems.filter(suburb => suburb.id !== item.id);
    this.selectedSuburbItems = selectedSuburbItems;
    sessionStorage.setItem('selectedSuburbItems', JSON.stringify(this.selectedSuburbItems));

  }


  public onSuburbItemDeSelectAll(items: any) {
    this.model.property_location = [];
    this.selectedSuburbItems = [];
    sessionStorage.removeItem('selectedSuburbItems');
  }

  public onItemSelect(item: any) {
    this.itemIds.push(item.cityId);
    this.model.property_location = this.itemIds;
    this.updateSuburbsArray();

    sessionStorage.setItem('selectedItems', JSON.stringify(this.selectedCityItems));
  }

  public onSelectAll(items: any) {
    let itemIds: any = [];
    for (let item of items) {
      // @ts-ignore
      this.selectedCityItems.push(item);
      itemIds.push(item.cityId);
    }
    this.model.property_location = itemIds;
    this.updateSuburbsArray();
    sessionStorage.setItem('selectedItems', JSON.stringify(this.selectedCityItems));
  }

  public onItemDeSelect(item: any) {
    // @ts-ignore
    let selectedItems = this.selectedCityItems.filter(city => city.cityId !== item.cityId);
    this.selectedCityItems = selectedItems;

    let itemIds = this.itemIds.filter((id: any) => id !== item.cityId);
    this.itemIds = itemIds;


    this.model.property_location = this.itemIds;
    this.updateSuburbsArray();
    sessionStorage.setItem('selectedItems', JSON.stringify(this.selectedCityItems));

  }

  updateSuburbsArray = () => {
    this.model.suburbId = '';
    this.selectedSuburbItems = [];
    sessionStorage.removeItem('selectedSuburbItems');
    this.suburbs = [];
    if (this.model.property_location) {
      this.disableSuburbs = false;
      for (let location of this.model.property_location) {
        for (let city of this.cities) {
          if (city.cityId == location) {
            this.suburbs.push(...city.suburbs);
          }
        }
      }

      this.suburbs.sort((a: { name: number; }, b: { name: number; }) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    }
    else {
      this.suburbs = [];
    }
    this.checkWarning();
  };

  public onItemDeSelectAll(items: any) {
    this.suburbs = [];
    this.model.property_location = [];
    this.selectedCityItems = [];
    sessionStorage.removeItem('selectedItems');
  }
  checkWarning() {
    let property = this.propertyType.find((item: { id: any; }) => item.id == this.model.property_type) || {};
    if (property.propertyTypeName == 'Land') {
      this.bedRoomControlDisabled = true;
    } else {
      this.bedRoomControlDisabled = false;
    }
    this.warn = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.innitDropDowns();
    this.getRequiredDate();
    this.searchTermModel = {};
    this.setClasses();
    if (changes['showLocation']?.currentValue || changes['showProperty']?.currentValue || changes['showPrice']?.currentValue) {
      this.selectedCountryItems = [];
      this.selectedCityItems = [];
      this.selectedSuburbItems = [];
      this.searchTermModel = {};
      this.itemIds = [];
    }
  }

  private getRequiredDate() {
    if (this.fetchData === true) {
      if (this.countries.length == 0) {
        this.getActiveCountries();
      }
      if (this.cities.length == 0) {
        this.getCities();
      }
      if (this.propertyType.length == 0) {
        this.getPropertyType();
      }
    }
  }
  //country dropdown functions
  public onCountryItemSelect(item: any) {
    this.updateLocation();
    sessionStorage.setItem('selectedCountryItems', JSON.stringify(this.selectedCountryItems));
  }

  public onCountrySelectAll(items: any) {
    this.updateLocation();
    sessionStorage.setItem('selectedCountryItems', JSON.stringify(this.selectedCountryItems));
  }

  public onCountryItemDeSelect(item: any) {
    // @ts-ignore
    let selectedCountryItems = this.selectedCountryItems.filter(country => country.id !== item.id);
    this.selectedCountryItems = selectedCountryItems;
    this.updateLocation();
    sessionStorage.setItem('selectedCountryItems', JSON.stringify(this.selectedCountryItems));

  }

  public onCountryItemDeSelectAll(items: any) {
    this.suburbs = [];
    this.model.property_location = [];
    this.selectedCountryItems = [];
    this.selectedCityItems = [];
    sessionStorage.removeItem('selectedCountryItems');
  }

  updateLocation() {
    //deselect all options
    this.suburbs = [];
    this.selectedSuburbItems = [];
    this.model.suburbId = '';
    this.model.property_location = [];
    this.selectedCityItems = [];
    sessionStorage.removeItem('selectedItems');
    sessionStorage.removeItem('selectedSuburbItems');

    if (this.selectedCountryItems.length) {

      // @ts-ignore
      let cities = [];
      for (let country of this.selectedCountryItems) {
        // @ts-ignore
        let c = this.dropdownList.filter(city => city.countryId == country.id);
        cities.push(...c);
      }
      // @ts-ignore
      this.cities = cities;
    } else {
      this.cities = [...this.dropdownList];
    }

  }

  private setClasses() {


    if (this.showProperty && !this.showLocation && !this.showPrice) {
      this.bedName = 'Bedroom';
      this.bathName = 'Bathroom'
      this.propertyTypeName = 'Property Type'
      this.propertyTypeClass = 'col-md-4 col-sm-6';
      this.bedRoomsClass = 'col-md-3 col-sm-6';
      this.bathRoomsClass = 'col-md-3 col-sm-6';
      this.formClas = 'search-form';
      //this.deselectLocationValues();
    }
    else if (!this.showProperty && this.showLocation && !this.showPrice) {
      this.countryClass = 'col-md-4 col-sm-6';
      this.cityClass = 'col-md-3 col-sm-6';
      this.suburbClass = 'col-md-4 col-sm-6';
      this.formClas = 'search-form2';
    }
    else if (!this.showProperty && !this.showLocation && this.showPrice) {
      this.maxPriceClass = 'col-md-5 col-sm-6';
      this.minPriceClass = 'col-md-5 col-sm-6';
      this.formClas = 'search-form';
      //this.deselectLocationValues();
    }
    else {
      this.bedName = 'Bed';
      this.bathName = 'Bath'
      this.propertyTypeName = 'Property Type'
      this.formClas = 'search-form4';
      this.propertyTypeClass = 'col-lg-1 col-md-2 col-sm-6';
      this.bedRoomsClass = 'col-lg-1 col-md-2 col-sm-6';
      this.bathRoomsClass = 'col-lg-1 col-md-2 col-sm-6';
      this.countryClass = 'col-md-2 col-sm-6';
      this.cityClass = 'col-md-2 col-sm-6';
      this.suburbClass = 'col-md-2 col-sm-6';
      this.maxPriceClass = 'col-lg-1 col-md-2 col-sm-6';
      this.minPriceClass = 'col-lg-1 col-md-2 col-sm-6';
      //this.deselectLocationValues();
    }
  }

  private innitDropDowns() {
    this.cityDropdownSettings = {
      singleSelection: false,
      idField: 'cityId',
      textField: 'cityName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.countryDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'countryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.suburbDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    let rawSearchTerm = sessionStorage.getItem('searchTerm');
    let suburbs = sessionStorage.getItem('suburbs');
    let selectedItems = sessionStorage.getItem('selectedItems');
    let selectedCountryItems = sessionStorage.getItem('selectedCountryItems');
    let selectedSuburbItems = sessionStorage.getItem('selectedSuburbItems');

    if (rawSearchTerm) {
      this.model = JSON.parse(rawSearchTerm);
      // @ts-ignore
      this.selectedCityItems = JSON.parse(selectedItems);
      // @ts-ignore
      this.selectedCountryItems = JSON.parse(selectedCountryItems);
      // @ts-ignore
      this.selectedSuburbItems = JSON.parse(selectedSuburbItems);

    }
    else {
      this.model = {};
      this.selectedCityItems = [];
      this.selectedCountryItems = [];
      this.selectedSuburbItems = [];
    }

    if (suburbs) {
      this.suburbs = JSON.parse(suburbs);
    }
    else {
      this.suburbs = [];
    }
    let urlBits = window.location.href.split('/');
    if (urlBits.length < 5) {
      this.model = {};
      sessionStorage.removeItem('searchTerm');
    }
    if (window.location.href.includes('properties')) {
      this.model = {};
      sessionStorage.removeItem('searchTerm');
    }

  }

  public populateSearchFields = () => {
    if (this.selectedCountryItems?.length) {
      // @ts-ignore
      let cities = [];
      for (let country of this.selectedCountryItems) {
        // @ts-ignore
        let c = this.dropdownList.filter(city => city.countryId == country.id);
        cities.push(...c);
      }
      // @ts-ignore
      this.cities = cities;
    } else {
      this.cities = [...this.dropdownList];
    }

    if (this.selectedCityItems) {
      this.suburbs = [];
      if (this.model.property_location) {
        this.disableSuburbs = false;
        for (let location of this.model.property_location) {
          for (let city of this.cities) {
            if (city.cityId == location) {
              this.suburbs.push(...city.suburbs);
            }
          }
        }
        // @ts-ignore
        this.suburbs.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      }
      else {
        this.suburbs = [];
      }
      this.checkWarning();
    }
  };

  private deselectLocationValues() {
    sessionStorage.removeItem('selectedItems');
    sessionStorage.removeItem('selectedCountryItems');
    sessionStorage.removeItem('selectedSuburbItems');
  }

  searchbarDropdown(url: string) {
    if (url.includes('/search-results/search')) {
      this.showSearch = true;
    } else {
      this.showSearch = false;
    }
  }
}
