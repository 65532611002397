import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { HttpEvent, HttpEventType } from "@angular/common/http";
import {AlertService, CreateApiService} from "@/shared";
// @ts-ignore
import Swal from "sweetalert2";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public date:any;
  public model: any = {};
  private loadingNewsletter= false;
  // @ViewChild('clearNewsletter', { static: false}) clearNewsletter: ElementRef;
  @ViewChild('clearNewsletter') clearNewsletter: ElementRef | undefined;
  constructor(private createApiService: CreateApiService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.date = Date.now();
  }

  public onSubmit(): void {
    this.createApiService
      .addServices("user/newsLetter", this.model)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.loadingNewsletter = true;
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.Response:
            const result = JSON.parse(event.body);

            this.loadingNewsletter = false;
            if (result.status === 200) {
              this.model = {};
              this.clearNewsletter?.nativeElement.click();
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thank you for subscribing to the Seso newsletter!'
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: result.message
              })
            }
            break;
        }
      });
  }
}
