import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'cypress/types/jquery';
import { environment } from 'environments/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { AnalyticsEvent, LocationInfo } from '../_interfaces/analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private baseUrl: string = environment._marketingEndpoint;

  constructor(private _http: HttpClient) { }

  async setGeolocationDetails(): Promise<string | undefined> {
    let info = this.getCookie('seso_geolocation');
    if (!info) {
      try {
        const data: LocationInfo = await firstValueFrom(
          this._http.get<LocationInfo>(`https://ipinfo.io/json`)
        );
        info = JSON.stringify(data);
        document.cookie = `seso_geolocation=${info}; path=/; max-age=86400`;

      } catch (error) {
        console.log("Could not get geolocation ")
      }
    } else {
      console.log('info already exists in cookie:');
    }
    return info
  }

  sendAnalytics(data: AnalyticsEvent): Observable<any> {
    return this._http.post(`${this.baseUrl}/api/v1/events`, data)
  }

  getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift();
    }
    return undefined;
  }

  setUUIDCookie(): string {
    let uuid = this.getCookie('user_uuid');
    if (!uuid) {
      uuid = crypto.randomUUID();  // Generate a UUID
      document.cookie = `user_uuid=${uuid}; path=/; max-age=31536000`; // Set cookie for 1 year
    }
    return uuid;
  }




}
