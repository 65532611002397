import {Injectable} from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';


import {ApiResponse} from '../_models/api-response';
import {environment} from './../../../environments/environment';

@Injectable()
export class CreateApiService {

    public baseUrl: string = environment._apiEndpoint;
    public affiliateBaseUrl: string = environment._apiEndpoint;
    public currentUser: any = sessionStorage.getItem('currentUser');
    public userDetails: any = {};
    public token?: string;
    public headers: any = new HttpHeaders();
    public isAuthToken = false;

    constructor(private _http: HttpClient) {
        this.headers.append('Content-Type', 'application/json');

        if (this.currentUser) {
            this.userDetails = JSON.parse(this.currentUser);
            this.token = this.userDetails.token;
            this.isAuthToken = true;
        }
    }



    addTask(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        // const formdata: FormData = new FormData();
         // tslint:disable-next-line:prefer-const
       let formData: any = {};
        formData.name = data.name;
        if (data.assignee !== null) {
            formData.assignee = data.assignee;
        } else {
            formData.assignee = ' ';
        }
        if (data.status   != null) {
            formData.status = data.status;
        } else {
            formData.status = 'NOT_STARTED';
        } if (data.description   != null) {
            formData.description = data.description;
        } else {
            formData.description =  ' ';
        } if (data.priority   != null) {
            formData.priority = data.priority;
        } else {
            formData.priority = 'LOW';
        }
        if (data.dueDate   != null) {
            formData.dueDate = new Date(data.dueDate);
        } else {
            formData.dueDate = ' ';
        }
        formData.companyId = data.companyId;
        formData.createdBy = data.createdBy;
        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formData, {
            reportProgress: true,

        });

        return this._http.request(req);
    }
    addInstallmentPayment(endPoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const formData: any = {};
        formData.name = data.name;
        formData.description = data.description;
        formData.termNumber = data.termNumber;
        formData.termType = data.termType;
        formData.paymentType = data.paymentType;
        formData.companyId = data.companyId;
        formData.propertyOwnerId = data.createdBy;
        formData.interest = data.interest;
        formData.tax = data.tax;
        formData.commission = data.commission;
        formData.createdBy = data.createdBy;
        const req = new HttpRequest('POST', `${this.baseUrl}/${endPoint}`, formData, {
            reportProgress: true,

        });

        return this._http.request(req);
    }


    uploadFile(endpoint: string, id: string, file: File): Observable<HttpEvent<ApiResponse<any>>> {

        const formdata: FormData = new FormData();

        formdata.append('propertyId', id);
        formdata.append('documentName', 'Document');
        formdata.append('file', file);

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formdata, {
            reportProgress: true,

        });

        return this._http.request(req);
    }

    uploadPropertyPhotos(endpoint: string, id: string, files: File[]): Observable<HttpEvent<ApiResponse<any>>> {

        const formdata: FormData = new FormData();

        formdata.append('propertyId', id);

        for (const file of files) {
            formdata.append('file', file);
        }
        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formdata, {
            reportProgress: true,

        });

        return this._http.request(req);
    }

    sendTourMessage(endpoint: string, data: any, file?: File): Observable<HttpEvent<ApiResponse<any>>> {
        const formdata: FormData = new FormData();

        formdata.append('userId', data.userId);
        formdata.append('message', data.message);
        formdata.append('tourId', data.tourId);
        if (file) {
            formdata.append('file', file);
        }

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formdata, {
            reportProgress: true,

        });
        return this._http.request(req);
    }

    postForeignProperty(endpoint: string, data: any, file?: File): Observable<HttpEvent<ApiResponse<any>>> {
        const formdata: FormData = new FormData();

        for (const key in data) {
            formdata.append(key, data[key]);
        }
        if (file) {
            formdata.append('file', file);
        }

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formdata, {
            reportProgress: true,

        });
        return this._http.request(req);
    }


    paymentPlanRequest(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const formdata: FormData = new FormData();

        formdata.append('paymentPlanId', data.paymentPlanId);
        formdata.append('propertyId', data.propertyId);
        formdata.append('requesterId', data.requesterId);

        for (const doc of data.requiredDocs) {
            formdata.append('requiredDocs', doc);
        }
        if (data.dateOfPayment) {
            formdata.append('dateOfPayment', data.dateOfPayment);
        }

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formdata, {
            reportProgress: true,

        });
        return this._http.request(req);
    }

    uploadPaymentReceipt(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const formdata: FormData = new FormData();

        formdata.append('proofOfPayment', data.proofOfPayment);
        formdata.append('paymentPlanRequestId', data.paymentPlanRequestId);

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formdata, {
            reportProgress: true,

        });
        return this._http.request(req);
    }
    uploadDocFile(endpoint: string, docType: string, file: File,  id: string): Observable<HttpEvent<ApiResponse<any>>> {
        const fd: FormData = new FormData();
        fd.append('documentTypeId ', docType);
        fd.append('file', file);
        fd.append('userId', id);

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, fd, {
            reportProgress: true,

        });

        return this._http.request(req);
    }
    uploadHelpDocFile(endpoint: string, category: string, file: File, name: string): Observable<HttpEvent<ApiResponse<any>>> {
        const formData = new FormData();
        formData.append('category ', category);
        formData.append('file', file);
        formData.append('name', name);

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formData, {
            reportProgress: true,

        });

        return this._http.request(req);
    }

    confirmPaymentReceipt(endpoint: string, id: string): Observable<HttpEvent<ApiResponse<any>>> {
        const formData = new FormData();
        formData.append('paymentPlanRequestId ', id);
        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formData, {
            reportProgress: true,
        });
        return this._http.request(req);
    }



    /* Read single record API endpoint */
    addServices(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('POST', url, data, {
            reportProgress: true,
        });

        return this._http.request(req);
    }

     addCustomeService(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.affiliateBaseUrl}/${endpoint}`;
        const req = new HttpRequest('POST', url, data, {
            reportProgress: true,
        });

        return this._http.request(req);
    }

    addPaymentPlan(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('POST', url, data, {
            reportProgress: true,
        });

        return this._http.request(req);
    }

    processPaymentPlan(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('POST', url, data, {
            reportProgress: true,

        });

        return this._http.request(req);
    }

    editPaymentPlan(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('PUT', url, data, {
            reportProgress: true,

        });

        return this._http.request(req);
    }

    updateService(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('PUT', url, data, {
            reportProgress: true,

        });

        return this._http.request(req);
    }

    addServiceRequest(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;

        const req = new HttpRequest('POST', url, data, {
            reportProgress: true,

        });

        return this._http.request(req);
    }
    addFileServiceRequest(endpoint: string, formData: FormData): Observable<HttpEvent<ApiResponse<any>>> {

        const req = new HttpRequest('POST', `${this.baseUrl}/${endpoint}`, formData, {
            reportProgress: true,

        });

        return this._http.request(req);
    }
    addFaq(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const endPointUrl = `${this.baseUrl}/${endpoint}`;
        const mainRequest = new HttpRequest('POST', endPointUrl, data, { reportProgress: true, });
        return this._http.request(mainRequest);
    }

    updateTourRequest(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;

        const req = new HttpRequest('PUT', url, data, {
            reportProgress: true,
        });

        return this._http.request(req);
    }
    /* Read single record API endpoint */
    addServiceRequestWithToken(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {

        const url = `${this.baseUrl}/${endpoint}`;
        let req;

        req = new HttpRequest('POST', url, data, {
            headers: new HttpHeaders({
                Authorization: (this.token) ? this.token: ""
            }),
            reportProgress: true
        });


        return this._http.request(req);
    }
}
