import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Logger } from './logger.service';

const log = new Logger('HttpConfigInterceptor');

import { ApiResponse } from '../_models/api-response';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string  = environment._apiEndpoint;

  constructor(private _http: HttpClient, private _router: Router) { }

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('loggedIn');
    sessionStorage.clear();
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return sessionStorage.getItem('currentUser') != null && !this.isTokenExpired();
  }

  // simulate jwt token is valid
  // https://github.com/theo4u/angular4-auth/blob/master/src/app/helpers/jwt-helper.ts
  isTokenExpired(): boolean {
    return false;
  }

  storeLoginData(userData: string): void {
    sessionStorage.setItem('currentUser', userData);
  }
    storeNoticesOnLogIn(noticeData: string): void {
        sessionStorage.setItem('notice', (noticeData));
    }
    login(username: string, password: string):  any {
        const url = `${this.baseUrl}/auth/login`;
        const data = {
            'username': username,
            'password': password
        };

        const req = new HttpRequest('POST', url, data, {
          reportProgress: true,
          responseType: 'text'
        });

        return this._http.request(req);
  }

  /**
   * this is used to clear local storage and also the route to login
   */
  public logout(): void {
    this.clear();
    this._router.navigate(['/properties']);

    setTimeout(function () {
        window.location.reload();
    }, 250);
  }
}
