export const environment = {
  production: true,
  /* Live crendentials */
  _apiEndpoint: 'https://back.seso.global',
  _crm: 'https://crm.seso.global',
  _payStackApiKeys: 'pk_live_50af181a020550f3f190c2a8237ce7d2838b891f',
  _marketingEndpoint: 'https://api-marketing-prod.seso.global',
  _affiliateApiEndpoint: 'https://affiliates.seso.global/api',
  _geolocationEndpoint: 'https://ipinfo.io?token=',
  _geolocationToken: 'bdb1612f2f0dea',
  _analyticsCode: 'SM-KB65KLN3C0',
  _agmApiKey: 'AIzaSyBMU6DvSjeE-Los4vIVlIzf3G8BjvY6Eno',
  _paypalClientId: 'AfwcGrcjtKr0okhrnGuDzzFrDdkMagsSu5ii-ZXbgFj-IdOC235Sh7WAjzNHQHpnq24lTGaJzxfjbJra',
};
