<nav class="navbar navbar-expand-md navbar-light bg-white mb-0">
  <div class="container">
    <div class="row" style="flex-grow: 1; vertical-align: middle;">
      <div class="col-md-4 col-sm-12">
        <a class="navbar-brand d-flex align-items-center" href="./"><img src="assets/images/seso-logo.png"
            alt="Example Navbar 1" class="mr-2" height="50" />
          &nbsp;</a>
        <div class="dropdown" (mouseleave)="toggleDropdownMT=false">
          <button class="navbtn mobile-hamburgerbtn" (click)="toggleMDBT()" type="button" data-toggle="collapse"
            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span> Menu
          </button>
          <div class="sub-menu" *ngIf="toggleDropdownMT" (mouseleave)="toggleDropdownMT=false">
            <a class="dropdown-item" [routerLinkActive]="['is-active']" *ngIf="userDetails">{{
              userDetails.user.firstName }} {{ userDetails.user.lastName
              }}</a>
            <!-- <a class="dropdown-item" routerLink="/user-profile/about-me" [routerLinkActive]="['is-active']"
              *ngIf="userDetails.user =='buyer' || userDetails.user =='tenant' ">Profile</a> -->
            <a class="dropdown-item" href="javascript:void(0);" (click)="goToUserProfile()">{{ profileText }}</a>
            <a class="dropdown-item" routerLink="/" [routerLinkActive]="['is-active']">Home</a>
            <a class="dropdown-item" routerLink="/properties" [routerLinkActive]="['is-active']">Properties</a>
            <a class="dropdown-item" routerLink="/service-providers" [routerLinkActive]="['is-active']">Get Service</a>
            <a class="dropdown-item" routerLink="/make-request" [routerLinkActive]="['is-active']">Make Inquiry</a>
            <a class="dropdown-item" routerLink="/mortgage" [routerLinkActive]="['is-active']">Mortgage</a>
            <a class="dropdown-item" routerLink="/dealroom" [routerLinkActive]="['is-active']">Deal Room</a>
            <a class="dropdown-item" routerLink="/overseas" [routerLinkActive]="['is-active']">Overseas</a>
            <a class="dropdown-item" routerLink="/crm" [routerLinkActive]="['is-active']">CRM</a>
            <a class="dropdown-item" routerLink="/ghana-luxury-tour-info" [routerLinkActive]="['is-active']">Ghana Luxury Tour</a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="gotToAboutUse()">About Us</a>
            <a class="dropdown-item" routerLink="/login" [routerLinkActive]="['is-active']"
              *ngIf="!userDetails">login</a>
            <a class="dropdown-item" routerLink="/sign-up" [routerLinkActive]="['is-active']" *ngIf="!userDetails">Sign
              Up</a>
            <a class="dropdown-item" (click)="showAlert()" style="cursor: pointer;" *ngIf="userDetails">
              <i class="fa fa-sign-out"></i> Logout
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12" style="z-index: 1000;">
        <div class="my-div">
          <ul>
            <li id="propertyFilterButton">
              <span class="mouse-action" [ngClass]="{'highlight': showPropertyH}"
                (click)="switchSearchDisplay('property')">Property</span>
            </li>
            <li id="locationFilterButton">
              <span class="mouse-action" [ngClass]="{'highlight': showLocationH}"
                (click)="switchSearchDisplay('location')">Location</span>
            </li>
            <li id="listPriceFilterButton">
              <span class="mouse-action" [ngClass]="{'highlight': showPriceH}"
                (click)="switchSearchDisplay('price')">Price</span>
            </li>
            <li *ngIf="!showSearch">
              <div class="circle mouse-action" (click)="switchSearchDisplay('all')">
                <i class="fas fa-filter"></i>
              </div>
            </li>
            <li *ngIf="showSearch">
              <div class="circle mouse-action" (click)="switchSearchDisplay('none')">
                <i class="fas fa-close"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 col-md-4 col-sm-3 seso-nav" style="z-index: 999;">
        <div class="navbar main-nav" style="justify-content: flex-end;">

          <button class="navbtn" id="" routerLink="/properties">
            Properties
          </button>
          <div class="dropdown" (click)="toggleDB()" (mouseleave)="toggleDropdown=false">
            <button class="dropbtn" id="ourServiceMenu">
              Services
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="sub-menu" *ngIf="toggleDropdown" (mouseenter)="toggleDropdown=true"
              (mouseleave)="toggleDropdown=false">
              <!-- <a class="dropdown-item" routerLink="/make-request" [routerLinkActive]="['is-active']">Make Inquiry</a> -->
              <a class="dropdown-item" routerLink="/mortgage" [routerLinkActive]="['is-active']">Mortgage</a>
              <a class="dropdown-item" routerLink="/dealroom" [routerLinkActive]="['is-active']">Deal Room</a>
              <a class="dropdown-item" routerLink="/service-providers" [routerLinkActive]="['is-active']">Get
                Service</a>
              <a class="dropdown-item" routerLink="/overseas" [routerLinkActive]="['is-active']">Overseas</a>
              <a class="dropdown-item" routerLink="/crm" [routerLinkActive]="['is-active']">CRM</a>
              <a class="dropdown-item" routerLink="/ghana-luxury-tour-info" [routerLinkActive]="['is-active']">Ghana Luxury Tour</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="goToAffiliatePartnership()">Affiliate Partners</a>
            </div>
          </div>
          <button class="navbtn" id="" routerLink="/make-request">Make Inqury</button>
          <button class="navbtn" (click)="gotToAboutUse()">About Us</button>
          <button class="navbtn" routerLink="/login" *ngIf="!userDetails">
            Login
          </button>
          <button class="navbtn" routerLink="/sign-up" *ngIf="!userDetails">
            Sign Up
          </button>
          <div class="dropdown" *ngIf="userDetails" style="margin-left: 10px;" (mouseleave)="profileDropDown=false">
            <div class="my-profile mouse-action" (click)="toggleProfileMenu()">
              <span><i class="fa-solid fa-bars" style="color: #fcb022; margin-right: 10px;"></i></span>
              <span><i class="fa-regular fa-circle-user fa-xl"></i></span>
            </div>
            <div class="sub-menu" *ngIf="profileDropDown" (mouseleave)="profileDropDown=false">
              <a class="dropdown-item" [routerLinkActive]="['is-active']">{{ userDetails.user.firstName }} {{
                userDetails.user.lastName
                }}</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="goToUserProfile()">{{ profileText }}</a>
              <a class="dropdown-item" (click)="showAlert()" style="cursor: pointer;">
                <i class="fa fa-sign-out"></i> Logout
              </a>
            </div>
          </div>
        </div>
        <div class="dropdown hamburgerbtn" style="justify-content: flex-end;" (mouseleave)="toggleDropdownM=false">
          <!-- Hamburger icon navbar-toggler-->
          <button class="navbtn" type="button" (click)="toggleMDB()" data-bs-toggle="collapse"
            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span> Menu
          </button>
          <div class="sub-menu" *ngIf="toggleDropdownM" (mouseleave)="toggleDropdownM=false">
            <a class="dropdown-item" [routerLinkActive]="['is-active']" *ngIf="userDetails">{{
              userDetails.user.firstName }} {{ userDetails.user.lastName
              }}</a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="goToUserProfile()">{{ profileText }}</a>
            <a class="dropdown-item" routerLink="/" [routerLinkActive]="['is-active']">Home</a>
            <a class="dropdown-item" routerLink="/properties" [routerLinkActive]="['is-active']">Properties</a>
            <a class="dropdown-item" routerLink="/make-request" [routerLinkActive]="['is-active']">Make Inquiry</a>
            <a class="dropdown-item" routerLink="/mortgage" [routerLinkActive]="['is-active']">Mortgage</a>
            <a class="dropdown-item" routerLink="/dealroom" [routerLinkActive]="['is-active']">Deal Room</a>
            <a class="dropdown-item" routerLink="/service-providers" [routerLinkActive]="['is-active']">Get Service</a>
            <a class="dropdown-item" routerLink="/overseas" [routerLinkActive]="['is-active']">Overseas</a>
            <a class="dropdown-item" routerLink="/crm" [routerLinkActive]="['is-active']">CRM</a>
            <a class="dropdown-item" routerLink="/ghana-luxury-tour-info" [routerLinkActive]="['is-active']">Ghana Luxury Tour</a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="goToAffiliatePartnership()">>Affiliate Partners</a>
            <a class="dropdown-item" href="javascript:void(0);" (click)="gotToAboutUse()">About Us</a>
            <a class="dropdown-item" routerLink="/login" [routerLinkActive]="['is-active']"
              *ngIf="!userDetails">Login</a>
            <a class="dropdown-item" routerLink="/sign-up" [routerLinkActive]="['is-active']" *ngIf="!userDetails">Sign
              Up</a>
            <a class="dropdown-item" (click)="showAlert()" style="cursor: pointer;" *ngIf="userDetails">
              <i class="fa fa-sign-out"></i> Logout
            </a>
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
  </div>
</nav>
<!-- Search bar -->

<!-- New Navbar -->
<!-- End new navbar -->

<app-search-component [showSearch]="showSearch" [showProperty]="showProperty" [showLocation]="showLocation"
  [showPrice]="showPrice" [fetchData]="fetchData">
</app-search-component>
