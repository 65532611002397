<nav class="navbar navbar-expand-md navbar-light bg-white mb-0" *ngIf="showSearch">
  <div class="container">
    <div class="row" style="flex-grow: 3; vertical-align: middle;">
      <div style="display: flex; justify-content: center">
        <form name="search" (ngSubmit)="submitSearch()" #searchForm="ngForm" [ngClass]="formClas">
          <div class="row">
            <div [ngClass]="propertyTypeClass" *ngIf="showProperty">
              <select class="form-control input-md" [ngClass]="{'prop-text': showProperty && !this.showLocation && !this.showPrice}" [(ngModel)]="searchTermModel.propType" name="propType"
                #propType="ngModel" id="selectPropertyType" style="font-size: 11px; width: 100px; height: 38px;">
                <option [ngValue]="value">{{propertyTypeName}}</option>
                <option value="{{ propType.id }}" *ngFor="let propType of propertyType">
                  {{ propType.propertyTypeName }}
                </option>
              </select>
            </div>
            <div [ngClass]="bedRoomsClass" *ngIf="showProperty">
              <select id="selectNoBedroom" class="form-control input-md" [(ngModel)]="searchTermModel.bedroom"
                name="bedroom" [disabled]="bedRoomControlDisabled" #bedroom="ngModel" style="font-size: 13px; width: 100px; height: 38px;">
                <option [ngValue]="value">{{bedName}}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div [ngClass]="bathRoomsClass" *ngIf="showProperty">
              <select class="form-control input-md" [(ngModel)]="searchTermModel.bathroom" name="bathroom"
                [disabled]="bathRoomControlDisabled" #bathroom="ngModel" id="selectNoBathroom" style="font-size: 13px; width: 97px; height: 38px;">
                <option [ngValue]="value">{{bathName}}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div [ngClass]="countryClass" class="input-md" *ngIf="showLocation">
              <ng-multiselect-dropdown class="nmd"
                  [ngModelOptions]="{standalone: true}"
                  [placeholder]="'Country'"
                  [settings]="countryDropdownSettings"
                  [data]="countries" 
                  [(ngModel)]="selectedCountryItems"
                  (onSelect)="onCountryItemSelect($event)"
                  (onSelectAll)="onCountrySelectAll($event)"
                  (onDeSelect)="onCountryItemDeSelect($event)"
                  (onDeSelectAll)="onCountryItemDeSelectAll($event)" style="font-size: 13px;">
              </ng-multiselect-dropdown>
            </div>
            <div [ngClass]="cityClass" class="input-md" *ngIf="showLocation">
              <label class="sr-only">Name</label>
              <ng-multiselect-dropdown class="nmd" [ngModelOptions]="{ standalone: true }" [placeholder]="'City'"
                [settings]="cityDropdownSettings" [data]="cities" [(ngModel)]="selectedCityItems"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)"  style="font-size: 13px;">
              </ng-multiselect-dropdown>
            </div>
            <div [ngClass]="suburbClass" class="input-md" *ngIf="showLocation">
              <label class="sr-only">SUBURB</label>
              <ng-multiselect-dropdown class="nmd" [ngModelOptions]="{ standalone: true }" [placeholder]="'Suburb'"
                [data]="suburbs" [(ngModel)]="selectedSuburbItems" [settings]="suburbDropdownSettings"
                (onSelect)="onSuburbItemSelect($event)" (onSelectAll)="onSuburbSelectAll($event)"
                (onDeSelect)="onSuburbItemDeSelect($event)" (onDeSelectAll)="onSuburbItemDeSelectAll($event)"  style="font-size: 13px;">
              </ng-multiselect-dropdown>
            </div>
            <div [ngClass]="minPriceClass" *ngIf="showPrice">
              <label class="sr-only">Min Price</label>
              <input type="text" class="text-box" [ngClass]="{'price-text': showPrice && !this.showProperty && !this.showLocation }" [(ngModel)]="searchTermModel.min_price" name="min_price" #min_price="ngModel"
                class="form-control input-md" placeholder="Min Price $"  value=" "  style="font-size: 13px; width: 100px; height: 38px;" autocomplete='off' mask="separator.2"
thousandSeparator=","/>
            </div>
            <div [ngClass]="maxPriceClass" *ngIf="showPrice">
              <label class="sr-only">Max Price</label>
              <input type="text" class="text-box" [ngClass]="{'price-text': showPrice && !this.showProperty && !this.showLocation }"  [(ngModel)]="searchTermModel.max_price" name="max_price" #max_price="ngModel"
                class="form-control input-md" placeholder="Max Price $" value=" " style="font-size: 13px; width: 100px; height: 38px;" mask="separator.2"
thousandSeparator=","/>
            </div>
            <div class="col-md-1 col-sm-6">
              <button type="submit" class="btn btn-warning mb-2" id="buttonSearchFilter">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</nav>
