import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface CommentObject {
  display?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor() { }

  private _commentAction = new BehaviorSubject<CommentObject>({});
  private _commentState: { commentAction: CommentObject } = { commentAction: {} };
  readonly commentDetails = this._commentAction.asObservable();

  private popupVisibility = new BehaviorSubject<boolean>(false);
  popupVisibility$ = this.popupVisibility.asObservable();

  public getState() {
    this.popupVisibility.next(!this.popupVisibility.value);
  }

  public setState(comentState: CommentObject) {
    this._commentState.commentAction = comentState;
    this._commentAction.next(Object.assign({}, this._commentState).commentAction);
  }
}
