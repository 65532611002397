export class SearchModel {
    constructor(
        public property_location?: string,
        public suburbId?: string,
        public property_type?: string,
        public property_developer?: string,
        public bank?: string,
        public bed_numbers?: number,
        public min_price?: number,
        public max_price?: number
    ){}
}
