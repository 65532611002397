import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';


import { ApiResponse } from '../_models/api-response';
import { environment } from './../../../environments/environment';

import { Logger } from './logger.service';

const log = new Logger('Update Service');

@Injectable()
export class UpdateApiService {

    // tslint:disable-next-line:indent
  	public baseUrl: string = environment._apiEndpoint;
    public currentUser: any = sessionStorage.getItem('currentUser');
    public userDetails: any = {};
    public token?: string;
    public headers: any = new HttpHeaders();
    public isAuthToken = false;

    constructor(private _http: HttpClient) {
        //log,debug('Create API service ready ...');

        this.headers.append('Authorization', 'application/json');

        if (this.currentUser) {
            this.userDetails = JSON.parse(this.currentUser);
            this.token = this.userDetails.token;
            this.isAuthToken = true;
        }
    }


    /* Update API endpoint */
    updateServices(endpoint: string, id: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}/${id}`;
        //log,debug(url);

        const req = new HttpRequest('PUT', url, data, {
          reportProgress: true,
          responseType: 'text'
        });
        return this._http.request(req);
    }
    /* Update API endpoint */
    updateServicesWithoutId(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        //log,debug(url);

        const req = new HttpRequest('PUT', url, data, {
          reportProgress: true,
          responseType: 'text'
        });
        return this._http.request(req);
    }

    /* Update API endpoint */
    updateLeadServices(endpoint: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const udpateData: any = {};
        udpateData.addressOfProperty = data.addressOfProperty;
        udpateData.salesRep = data.salesRep.id;
        udpateData.propertyId = 0;
        udpateData.phoneNumber = data.phoneNumber;
        udpateData.otherNames = data.otherNames;
        udpateData.location = data.location;
        udpateData.leadsType = data.leadType.id;
        udpateData.leadTime = data.leadTime;
        udpateData.leadSource = data.leadSource.id;
        udpateData.leadNeed = data.leadNeed;
        udpateData.leadAuthority = data.leadAuthority;
        udpateData.jobTitle = data.jobTitle;
        udpateData.firstName = data.firstName;
        udpateData.email = data.email;
        udpateData.developerId = data.company.id;
        udpateData.companyId = data.company.id;
        udpateData.company = data.companyName;
        udpateData.budgetAmount = data.budgetAmount;
        udpateData.budget = data.budget;
        udpateData.id = data.id;
        const url = `${this.baseUrl}/${endpoint}`;
        //log,debug(url);
        const req = new HttpRequest('PUT', url, udpateData, {
          reportProgress: true,
          responseType: 'text'
        });
        return this._http.request(req);
    }
    updateFaq(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const endPointUrl = `${this.baseUrl}/${endpoint}`;
        const mainRequest = new HttpRequest('PUT', endPointUrl, data, { reportProgress: true, responseType: 'text'});
        return this._http.request(mainRequest);
    }
    updateCompany(endpoint: string, id: string ): Observable<HttpEvent<ApiResponse<any>>> {
        endpoint += '?companyId=' + id;
        const endPointUrl = `${this.baseUrl}/${endpoint}`;
        const mainRequest = new HttpRequest('PUT', endPointUrl, id, {reportProgress: true, responseType : 'text'});
        return this._http.request(mainRequest);
    }

    /* Update API endpoint */
    updateServices2(endpoint: string, id: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;

        const req = new HttpRequest('PUT', url, data, {
            reportProgress: true,
            responseType: 'text'
        });

        return this._http.request(req);
    }

    /* Update API endpoint */

    /* Update API endpoint */
    updateTaskService(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const formData: any = {};
        formData.name = data.task_name;
        if (data.assignees !== null) {
            formData.assignee = data.assignees[0]['assignees']['id'];
        } else {
            formData.assignee = ' ';
        }
        if (data.status   != null) {
            formData.status = data.status;
        } else {
            formData.status = 'NOT_STARTED';
        } if (data.description   != null) {
            formData.description = data.description;
        } else {
            formData.description =  ' ';
        } if (data.priority   != null) {
            formData.priority = data.priority;
        } else {
            formData.priority = 'LOW';
        }
        if (data.dueDate   != null) {
            const d = new Date(data.dueDate).getDate();
            formData.dueDate = data.dueDate;
        } else {
            formData.dueDate = ' ';
        }
        if (data.comments != null) {
            formData.comments = data.comments;
        } else {
            formData.comments = ' ';
        }
        formData.companyId = data.companyId;
        formData.createdBy = data.createdBy.id;
        formData.id = data.id;
        const req = new HttpRequest('PUT', `${this.baseUrl}/${endpoint}`, formData, {
            reportProgress: true,
            responseType: 'text'
        });

        return this._http.request(req);
    }
    /*Update API endpoint */
    updateInstallmentPaymentService(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const formData: any = {};
        formData.name = data.name;
        formData.description = data.description;
        formData.termNumber = data.termNumber;
        formData.termType = data.termType;
        formData.paymentType = data.paymentType;
        formData.companyId = data.companyId;
        formData.id = data.id;
        formData.interest = data.interest;
        formData.tax = data.tax;
        formData.commission = data.commission;
        formData.status = data.status;
        formData.updatedBy = data.updatedBy;
        const req = new HttpRequest('PUT', `${this.baseUrl}/${endpoint}`, formData, {
            reportProgress: true,
            responseType: 'text'
        });

        return this._http.request(req);
    }

   /* update sub user */

    /* update sub user */

    /* Read single record API endpoint */
    updateServicesWithToken(endpoint: string, id: string, data: any):  Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;

        const req = new HttpRequest('PUT', url, data, {
            /* headers: new HttpHeaders({
            Authorization: this.token || ""
          }), */
          reportProgress: true
        });

        return this._http.request(req);
    }
    updateInstallmentAssignment(endpoint: string, data: any): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('PUT', `${this.baseUrl}/${endpoint}`, data, {
            reportProgress: true,
            responseType: 'text'
        });

        return this._http.request(req);
    }

    /* Update API endpoint */
    updateServicesWithOutToken(endpoint: string, data: any, file: any): Observable<HttpEvent<ApiResponse<any>>> {
      const formdata: FormData = new FormData();
         formdata.append('userPixUrl', '');
        if (file !== null) {
            formdata.append('file', file[0]);
        }else{

        }

        formdata.append('username', data.username);
        formdata.append('role', data.role);
        formdata.append('phoneNumber', data.phoneNumber);
        formdata.append('firstName', data.firstName);
        formdata.append('lastName', data.lastName);
        formdata.append('status', data.status);
        formdata.append('id', data.id);


        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('PUT', url, formdata, {
            /* headers: new HttpHeaders({
                Authorization: this.token || ''
            }), */
            responseType: 'text',
            reportProgress: true
        });
        return this._http.request(req);
    }

    /* Update API endpoint */
    updateServicesWithOutToken2(endpoint: string, data: any, file: any): Observable<HttpEvent<ApiResponse<any>>> {
      const formdata: FormData = new FormData();
        formdata.append('userPixUrl', data.userPixUrl);
        formdata.append('username', data.username);
        formdata.append('role', data.role);
        formdata.append('phoneNumber', data.phoneNumber);
        formdata.append('firstName', data.firstName);
        formdata.append('lastName', data.lastName);
        formdata.append('status', data.status);
        formdata.append('id', data.id);


        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('PUT', url, formdata, {
            /* headers: new HttpHeaders({
                Authorization: this.token || ''
            }), */
            responseType: 'text',
            reportProgress: true
        });
        return this._http.request(req);
    }

}
