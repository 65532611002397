import { Component, OnInit } from '@angular/core';
import { PopupService, CommentObject } from '@/shared/_utils/popup.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.css']
})
export class FloatingButtonComponent  implements OnInit{
  showPopup: boolean = false;
  public commentObj$!: Observable<CommentObject>;

  constructor(private _popupService: PopupService) {}

  ngOnInit() {
     this.commentObj$ = this._popupService.commentDetails;
    this.commentObj$.subscribe(visible => {
      this.showPopup = visible.display!;
    });
  }

  togglePopup() {
    //this.showPopup = !this.showPopup;
    const commentState = {
      //display: !this.showPopup
      display: true
    };
    this._popupService.setState(commentState);
  }

}
