import { Component, ElementRef, ViewChild} from "@angular/core";
import {CreateApiService, ReadApiService, Logger} from '@/shared';
import { PopupService, CommentObject } from "@/shared/_utils/popup.service";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { Observable } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.css"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter, :leave", [animate(300)]),
    ]),
  ],
})
export class PopupComponent {
  popupVisible = false;
  public feedbackModel: any = {};
  public loading: boolean = false;
  public commentObj$!: Observable<CommentObject>;
  @ViewChild('clearForm') clearForm?: ElementRef;
  constructor(private _popupService: PopupService, private _createApiService: CreateApiService,) {}

  ngOnInit() {
    this.commentObj$ = this._popupService.commentDetails;
    this.commentObj$.subscribe((visible) => {
      this.showPopup = visible.display!;
      this.popupVisible = visible.display!;
    });
  }

  closePopup() {
    this.clearForm?.nativeElement.click();
    const commentState = {
      //display: !this.showPopup
      display: false,
    };
    this._popupService.setState(commentState);
  }

  showPopup: boolean = true;

  togglePopup() {
    this.closePopup();
    this.showPopup = false;
  }

  submitFeedback() {
    this.loading = true;
    this.feedbackModel.page = window.location.href;
    this.sendFeedback();
  }

  //
  public sendFeedback() {
        //log,debug(data);
        this._createApiService.addServices('user/issue_report/', this.feedbackModel)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.loading = true;
                        //log,debug('Wish Added!');
                        break;
                    case HttpEventType.ResponseHeader:
                        ////log,debug('Respclearonse header received!');
                        break;
                    case HttpEventType.Response:
                        //log,debug('😺 Done!', event.body);
                        this.loading = false;

                        const {data, status, message} = event.body;
                        if(status === 200){
                          this.closePopup();
                          Swal.fire({
                            icon: "success",
                            title: "Success",
                            html: `<p>Thank you for sharing your feedback with us! <br/>We will get back to you soon.</p>`
                          });
                        }else{
                          this.loading = false;
                        }


                        break;

                }
            });


    }
}
