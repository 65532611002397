import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';
import {
  AlertService,
  CreateApiService,
  DeleteApiService,
  ReadApiService,
  UpdateApiService,
  ModulePreloadingStrategyService,
} from "@/shared/index";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
//import { ServiceWorkerModule } from '@angular/service-worker';
import { UtilModule } from "./modules/util/util.module";
import { AppComponent } from "./main/app.component";
import {
  HeaderComponent,
  SearchComponentComponent,
  FooterComponent,
  BuyerPortalLayoutComponent,
} from './layout'

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxMaskModule } from 'ngx-mask';
import { GoogleMapsModule } from '@angular/google-maps'
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SearchComponentComponent,
    FooterComponent,
    BuyerPortalLayoutComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule,
    NgxSkeletonLoaderModule,
    // Angular4PaystackModule,
    UtilModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    GoogleMapsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    CreateApiService,
    ReadApiService,
    UpdateApiService,
    DeleteApiService,
    AlertService,
    ModulePreloadingStrategyService,
    provideHttpClient(withInterceptorsFromDi()),
  ],

})
export class AppModule { }
