
export interface LocationInfo {
    ip: string;
    hostname: string;
    city: string;
    region: string;
    country: string;
    loc: string;
    org: string;
    postal: string;
    timezone: string;
}

export interface AnalyticsEvent {
    origin: string,
    type: AnalyticsEventType,
    analytics_code: string,
    current_page: string,
    previous_page: string,
    page_title: string,
    country?: string,
    city?: string,
    meta_data: any,
    sid: string
}

export enum AnalyticsEventType {
    PageView = 'page_view',
    PropertyView = 'property_view',
    AdView = 'adView'
}