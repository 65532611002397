import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModulePreloadingStrategyService } from '@/shared';
import { BuyerPortalLayoutComponent } from './layout';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    component: BuyerPortalLayoutComponent,
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
    data: { preload: false }, // preload flag
  },
  {
    path: '',
    component: BuyerPortalLayoutComponent,
    loadChildren: () =>
      import('./modules/market-place/market-place.module').then(
        (m) => m.MarketPlaceModule
      ),
    data: { preload: false }, // preload flag
  },
  {
    path: '',
    component: BuyerPortalLayoutComponent,
    loadChildren: () =>
      import('./modules/services/services.module').then(
        (m) => m.ServicesModule
      ),
    data: { preload: false }, // preload flag
  },
  {
    path: '',
    component: BuyerPortalLayoutComponent,
    loadChildren: () =>
      import('./modules/buyer/buyer.module').then((m) => m.BuyerModule),
    data: { preload: false }, // preload flag
  },
  {
    path: '',
    component: BuyerPortalLayoutComponent,
    loadChildren: () =>
      import('./modules/luxury-tour-agent/luxury-tour-agent.module').then((m) => m.LuxuryTourAgentModule),
    data: { preload: false }, // preload flag
  },
  {
    path: '',
    component: BuyerPortalLayoutComponent,
    loadChildren: () =>
      import('./modules/terms/terms.module').then((m) => m.TermsModule),
    data: { preload: false }, // preload flag
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: ModulePreloadingStrategyService, // our preloading service here
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
