import { Injectable } from "@angular/core";
//import { Deal } from ".";

export interface Deal {
  id: number;
  isFeatured?: any;
  dealName: string;
  status: string;
  dealExpiry: string;
  comment: string;
  videoURL?: string;
  imageURL?: any;
  createdOn?: string;
  updatedOn?: any;
  location?: string;
  dealSize?: number;
  dealType?: string;
  fees?: string;
  minimumInvestment?: string;
  dealTerm?: string;
  targetIrr?: string;
}

export interface DealPaginate {
  total: number;
  deal_rooms: Deal[];
}

@Injectable({
  providedIn: "root",
})
export class PartnerService {
  private currentDeal: Deal | undefined;
  constructor() {}

  setDeal(deal: Deal) {
    this.currentDeal = deal;
  }

  getDeal() {
    return this.currentDeal;
  }
}
