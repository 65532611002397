import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface SearchObject {
  location?: string;
  countryId?: string;
  property_location?: string;
  suburbId?: string;
  type?: string;
  bedroom?: string;
  bathroom?: string;
  minPrice?: string;
  maxPrice?: string;
  searchSection?: string;
  selectedCountryItems?: any;
  selectedCityItems?: any;
  selectedSuburbItems?: any;
}

@Injectable({
  providedIn: 'root'
})
export class SearchStoreService {

  private _searchDetails = new BehaviorSubject<SearchObject>({});
  private _dataStore: { searchDetails: SearchObject } = { searchDetails: {} };
  readonly searchDetails = this._searchDetails.asObservable();

  constructor() { }

  public readAll(){
    this._searchDetails.next(Object.assign({}, this._dataStore).searchDetails);
  }

  public create(searchDetails: SearchObject) {
    sessionStorage.setItem('searchObj', JSON.stringify(searchDetails));
    this._dataStore.searchDetails = {};
    this._dataStore.searchDetails = searchDetails;
    this._searchDetails.next(Object.assign({}, this._dataStore).searchDetails);
  }

  public update(searchDetails: SearchObject){
    sessionStorage.setItem('searchObj', JSON.stringify(searchDetails));
    this._dataStore.searchDetails = searchDetails;
    this._searchDetails.next(Object.assign({}, this._dataStore).searchDetails);
  }

  public switchSearch(section: string){
    const searchData = JSON.parse(sessionStorage.getItem('searchObj') || '{}');
    searchData.searchSection = section;
    sessionStorage.setItem('searchObj', JSON.stringify(searchData));
    this._dataStore.searchDetails = searchData;
    this._searchDetails.next(Object.assign({}, this._dataStore).searchDetails);
  }

  public remove(){
    this._dataStore.searchDetails = {};
    this._searchDetails.next(Object.assign({}, this._dataStore).searchDetails);
  }
}
