
<footer class="footer-1 bg-light text-dark">
  <app-floating-button></app-floating-button>
  <div >
    <div class="row info-card" >
      <div class=" row location">
          <div class="col-lg-4  col-md-12 col-sm-12">
            <h3 style="font-weight: 500; font-size: 1rem;">About Seso</h3>
            <div class="text-box">
              <p style="text-align: justify">We operate across sub-saharan Africa with a current focus on West Africa.</p>
              <p style="text-align: justify">If you are a property developer, buyer or work within the real estate industry, we want to hear from you.</p>
            </div>
          </div>
          <!-- <div class=""> -->
            <div class="d-md-none d-lg-block col-lg-3  col-md-3 col-sm-12">
              <img src="assets/images/contacts-map.webp" alt="" style="width: 100%; height: auto;" class="img-responsive">
            </div>
          <!-- </div> -->

          <div class="col-lg-5  col-md-12 col-sm-12 info-outer">
              <h3 style="font-weight: 500; font-size: 1rem;">Our Locations</h3>
              <div class="row">
                  <div class="contact-card col-md-5 col-sm-12">
                    <label class="label-text">Ghana Office</label>
                    <p><a class="number-color" href="tel:+233501347447">(+233) 50 134 7447</a></p>
                  </div>
                  <div class="contact-card col-md-5 col-sm-12">
                    <label class="label-text">UK Office</label>
                    <!-- <p><a class="number-color" href="tel:+447867786989">(+44) 7867 786989</a></p> -->
                    <p><a class="number-color" href="tel:+447920048574">(+44) 7920 048574</a></p>
                  </div>
                  <div class="contact-card col-md-5 col-sm-12">
                  <label class="label-text">Nigeria Office</label>
                  <p><a class="number-color"  href="tel:+2349036999862">(+234) 903 699 9862</a></p>
                </div>
                <div class="contact-card col-md-5 col-sm-12">
                  <label class="label-text">USA Office</label>
                  <p><a class="number-color" href="tel:+16144034298">(+1) 614 403-4298</a></p>
                </div>
              </div>
          </div>
      </div>

    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center  d-none d-sm-block">
      <div style="background-color: #0f1828; width: 100vw; margin-left: 0; margin-right: 0; height: 500px; color: white; padding-top: 120px">
        <div class="row" style="margin-left: 100px; margin-right: 100px; height: 40%;">
          <!-- <div class=""> -->
            <div class="col-md-4 bottom">
              <p style="font-size: 24px;">Join our newsletter</p>
              <p style="font-size: 15px;">We'll send you a newletter once per week. No spam</p>
            </div>
            <div class="col-md-8 bottom" >
              <div class="text-right">

                <form (ngSubmit)="newsLetterForm.form.valid && onSubmit()" #newsLetterForm="ngForm">
                  <div class="col-md-8 form-group text-right" [ngClass]="{ 'has-error': newsLetterForm.submitted && !email.valid }">
                    <div class="input-group">
                      <input type="text" [(ngModel)]="model.email" #email="ngModel" class="form-control sign-up" id="email" name="email" placeholder="Enter email address" required>
                      <div class="input-group-append sign-up-button">
                        <button class="btn btn-warning text-center" style="margin: 10px; " type="submit">Sign Up</button>
                        <button type="reset" style="display: none;" #clearNewsletter></button>
                      </div>
                    </div>
                    <div *ngIf="newsLetterForm.submitted && !email.valid" style="font-size: large;" class="error">Please enter valid characters</div>
                  </div>
                </form>
              </div>
            </div>
          <!-- </div> -->
        </div>
        <div class="row" style="margin-left: 100px; margin-right: 100px;">
          <hr style="color: white; margin-top: 20px; margin-bottom: 80px;">
          <div class="col-md-2">
            <picture>
              <source srcset="assets/images/Seso_alt_1.webp" type="image/webp"  class="mr-2">
              <img src="assets/images/Seso_alt_1.png" alt="Example Navbar 1" class="mr-2" loading="lazy"> &nbsp;
            </picture>
          </div>
          <div class="col-md-8" >
            <p style="font-size: 24px;">Africa's Trusted <br />Property Marketplace</p>
          </div>
          <div class="col-md-2" >
            <div class="links footer-nav-links">
              <ul class="list-unstyled d-flex flex-column">
                <li routerLink="/disclaimer" style="cursor: pointer">Disclaimer</li>
                <li routerLink="/terms" style="cursor: pointer">Terms of use</li>
                <li routerLink="/privacy" style="cursor: pointer">Privacy  Policy</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="copyright">
      <div style="background-color: #fdc84a; width: 100vw; margin-left: 0; margin-right: 0; bottom: 0; padding-top: 30px; padding-bottom:20px;">
        <div class="row">
          <div class="col-md-6 col-sm-12" >
            <p class="medium">
              <span style="padding-left: 100px; padding-right: 20px;">Copyright &copy;</span>
              2019 - {{date | date: 'yyyy'}} All Rights Reserved
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p class="medium" style="margin: 8px; margin-right: 100px; float: right;">
              <span style="padding: 15px; cursor: pointer;"><a href="https://seso.global/aboutus/" target="_blank" class="footer-brand-links" rel="noopener"><i class="text-black fa fa-globe fa-xl"></i></a></span>
              <span style="padding: 15px; cursor: pointer;"><a href="https://twitter.com/SesoGlobal" target="_blank" class="footer-brand-links" rel="noopener"><i class="text-black fa-brands fa-twitter fa-xl"></i></a></span>
              <span style="padding: 15px; cursor: pointer;"><a href="https://api.whatsapp.com/send?phone=2349036999862" target="_blank" class="footer-brand-links" rel="noopener"><i class="text-black fa-brands fa-whatsapp fa-xl"></i></a></span>
              <span style="padding: 15px; cursor: pointer;"><a href="https://www.instagram.com/seso_global/" target="_blank" class="footer-brand-links" rel="noopener"><i class="text-black fa-brands fa-instagram fa-xl"></i></a></span>
              <span style="padding: 15px; cursor: pointer;"><a href="https://www.youtube.com/channel/UCw31FCF85fn7mU3ed2KNFgw" target="_blank" class="footer-brand-links" rel="noopener"><i class="text-black fa-brands fa-youtube fa-xl"></i></a></span>
              <span style="padding: 15px; cursor: pointer;"><a href="https://www.linkedin.com/company/seso-global-limited/" target="_blank" class="footer-brand-links" rel="noopener"><i class="text-black fa-brands fa-linkedin fa-xl"></i></a></span>
              <span style="padding: 15px; cursor: pointer;"><a href="https://web.facebook.com/sesogloballtd?_rdc=1&_rdr" target="_blank" class="footer-brand-links" rel="noopener"><i class="text-black fa-brands fa-facebook fa-xl"></i></a></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
