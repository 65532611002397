<!-- <div class="popup-container" [@popupState]="popupVisible ? 'visible' : 'hidden'"> -->
<div class="popup-container" *ngIf="showPopup" [@fade]>
  <div class="popup-content">
    <span class="close-button" (click)="togglePopup()">&times;</span>
    <h5>Share your feed back</h5>
    <form (ngSubmit)="feedbackForm.form.valid && submitFeedback()" #feedbackForm="ngForm">
      <label for="name">Name:</label>
      <input type="text"
            [(ngModel)]="feedbackModel.name"
            #name="ngModel"
            id="name"
            name="name"
            required>
      <div *ngIf="feedbackForm.submitted && !name.valid" style="font-size: large; font-style: italic;" class="error">
        Please enter your name
      </div>
      <label for="email">Email:</label>
      <input type="email"
              [(ngModel)]="feedbackModel.email"
              #email="ngModel"
              id="email"
              name="email"
              required>
      <div *ngIf="feedbackForm.submitted && !email.valid" style="font-size: large; font-style: italic;" class="error">
        Please enter your email
      </div>
      <label for="message">Feed back:</label>
      <textarea id="message"
              [(ngModel)]="feedbackModel.message"
              #message="ngModel"
              id="message"
              name="message"
              rows="5" required></textarea>
      <div *ngIf="feedbackForm.submitted && !message.valid" style="font-size: large; font-style: italic;" class="error">
        Please share your feedback
      </div>
      <button type="submit" *ngIf="!loading">Submit</button>
      <button type="button" *ngIf="loading" disabled>
        <i class="fas fa-spinner fa-pulse"></i>
      </button>
      <button type="reset" style="display: none;" #clearForm></button>
    </form>
  </div>
</div>
