import { Injectable } from '@angular/core';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {

  secretKey = "YourSecretKeyForEncryption&Descryption";
  constructor() { }

  encrypt(value : any) : string{
    return AES.encrypt(value, this.secretKey.trim()).toString();
  }

  /* decrypt(textToDecrypt : string){
    return AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(enc.Utf8);
  } */
}
