import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
// import {IntervalObservable} from 'rxjs-compat/observable/IntervalObservable';


import { environment } from './../../../environments/environment';
import { ApiResponse } from '../_models/api-response';
import { Logger } from './logger.service';

const log = new Logger('Read Service');

@Injectable()
export class ReadApiService {

    // tslint:disable-next-line:indent
    public baseUrl: string = environment._apiEndpoint;
    public marketingUrl: string = environment._marketingEndpoint
    public currentUser: any = sessionStorage.getItem('currentUser');
    public userDetails: any = {};
    public token?: string;
    public headers: any = new HttpHeaders();
    public isAuthToken = false;

    constructor(private _http: HttpClient) {
        //log.info('Read API service ready ...');
        this.headers.append('Authorization', 'application/json');

        //log,debug(this.currentUser);
        if (this.currentUser) {
            this.userDetails = JSON.parse(this.currentUser);
            this.token = this.userDetails.token;
            this.isAuthToken = true;
        }
    }

    /* Read API endpoint */
    readAllService(endpoint: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        const req = new HttpRequest('GET', url, {
            reportProgress: true
        });

        return this._http.request(req);
    }

    getAds(): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.marketingUrl}/api/v1/ads/marketplace`;
        const req = new HttpRequest('GET', url, {
            reportProgress: true
        });

        return this._http.request(req);
    }
    /* Read API endpoint */
    readGeolocationService(endpoint: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = endpoint;
        const req = new HttpRequest('GET', url, {
            reportProgress: true
        });

        return this._http.request(req);
    }

    /* Read single record API endpoint */
    readAllServiceToken(endpoint: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        let req;

        req = new HttpRequest('GET', url, {
            headers: new HttpHeaders({
                Authorization: this.token || ""
            }),
            reportProgress: true
        });


        return this._http.request(req);
    }

    /* Read single record API endpoint */
    readOneService(endpoint: string, id: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}/${id}`;
        //log,debug(url);

        const req = new HttpRequest('GET', url, {
            reportProgress: true
        });

        return this._http.request(req);
    }
    /* Read single record by email API endpoint */
    readOneByEmailService(endpoint: string, id: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}${id}`;

        const req = new HttpRequest('GET', url, {
            reportProgress: true
        });

        return this._http.request(req);
    }



    readOneServiceDifCall(endpoint: string, id: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}${id}`;
        //log,debug(url);

        const req = new HttpRequest('GET', url, {
            reportProgress: true
        });

        return this._http.request(req);
    }

    /* Read single record API endpoint */
    readOneServiceWithToken(endpoint: string, id: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}/${id}`;
        //log,debug(url);

        let req;

        req = new HttpRequest('GET', url, {
            headers: new HttpHeaders({
                Authorization: this.token || ""
            }),
            reportProgress: true
        });


        return this._http.request(req);
    }

    /* Read single record API endpoint */
    readOneServiceWithToken2(endpoint: string): Observable<HttpEvent<ApiResponse<any>>> {
        const url = `${this.baseUrl}/${endpoint}`;
        //log,debug(url);

        let req;

        req = new HttpRequest('GET', url, {
            headers: new HttpHeaders({
                Authorization: this.token || ""
            }),
            reportProgress: true
        });


        return this._http.request(req);
    }
}
