import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@/app.module';
import * as Sentry from "@sentry/angular";
import { environment } from './environments/environment';


if (environment.production) {
  Sentry.init({
    dsn: "https://1a56574d265f1c02e8210419e4ec412a@o4508449071300608.ingest.us.sentry.io/4508536686379008",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
