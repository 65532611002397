import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';


import { ApiResponse } from '../_models/api-response';
import { environment } from './../../../environments/environment';

import { Logger } from './logger.service';

const log = new Logger('Delete Service');

@Injectable()
export class DeleteApiService {

    public baseUrl:string = environment._apiEndpoint;
    public currentUser:any = sessionStorage.getItem('currentUser');
    public userDetails:any = {};
    public token?:string;
    public headers:any = new HttpHeaders();
    public isAuthToken:boolean = false;

    constructor(private _http: HttpClient) {
        //log,debug('Delete API service ready ...');
        this.headers.append('Authorization', 'application/json');
        if (this.currentUser) {
          	this.userDetails = JSON.parse(this.currentUser);
            this.token = this.userDetails.token;
            this.isAuthToken = true;
      	}
    }
    /* Create API endpoint */
    deleteService(endpoint: string, id: string) {
        const url = `${this.baseUrl}/${endpoint}/${id}`;
        let req;
            req = new HttpRequest('DELETE', url, {
              reportProgress: true,
              responseType: 'text'
            });

        return this._http.request(req);

    }

    deleteServiceWithBody(endpoint: string, data: any) {
        const url = `${this.baseUrl}/${endpoint}`;
        //log,debug(url);

        const formdata: FormData = new FormData();
        for (let property in data) {
            formdata.append(property, data[property]);
        }

        // const req = new HttpRequest('DELETE', url, {
        //     headers: new HttpHeaders({
        //     Authorization: this.token
        //   }),
        //   reportProgress: true,
        //   responseType: 'text'
        // });

        let req;

            req = new HttpRequest('DELETE', url, formdata, {
                reportProgress: true,
                responseType: 'text'
            });

        return this._http.request(req);

    }
    /* Create API endpoint */
    deleteService2(endpoint: string) {
        const url = `${this.baseUrl}/${endpoint}`;
        //log,debug(url);
        let req;
            req = new HttpRequest('DELETE', url, {
              reportProgress: true,
              responseType: 'text'
            });
        return this._http.request(req);
    }

}
